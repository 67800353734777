// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { cssBundleHref } from '@remix-run/css-bundle';
import { json, redirect } from '@remix-run/node';
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useLocation, useNavigate, useRouteError } from '@remix-run/react';
import { createHead } from 'remix-island';
import APIService from './services/ApiService';
import { checkIsAuthenticated, checkIsNeedToResetPassword, destroySession, getAuthToken, getSession, refreshSessionExpiration } from './sessions';
import font from './styles/font.css';
import otpstylesheet from './styles/otp.css';
import tailwindstylesheet from './styles/tailwind.css';
import { GeneralContext } from './utils/GeneralContext';
import { ConvertCurrencyProvider } from './hooks/useConvertCurrency';
import CompanyServices from './services/company-services/company-services';
import { InstitutionService } from './services/institution-services/institution-services';
import { ROLE_NAME } from './hooks/useAccessControl';
import { LoggerService } from './services/logger-service/logger-service';
import MaterialThemeProvider from './styles/material-theme';
import { Box, Typography } from '@mui/material';
import Column from './components/Column';
import Button from './components/Button';
import { KeyboardReturn } from '@mui/icons-material';
import { useEffect } from 'react';
export const logger = new LoggerService();
export const links = () => [...(cssBundleHref ? [{
  rel: 'stylesheet',
  href: cssBundleHref
}, {
  rel: 'stylesheet',
  href: tailwindstylesheet
}, {
  rel: 'stylesheet',
  href: font
}, {
  rel: 'stylesheet',
  href: otpstylesheet
}] : [{
  rel: 'stylesheet',
  href: font
}, {
  rel: 'stylesheet',
  href: tailwindstylesheet
}, {
  rel: 'stylesheet',
  href: otpstylesheet
}])];
export const loader = async ({
  request
}) => {
  logger.setBaseUrl(process.env.BASE_API_URL || '');
  const baseApiUrl = process.env.BASE_API_URL;
  // Check is authenticated
  const isAuthenticated = await checkIsAuthenticated(request);
  const host = new URL(request.url).hostname;
  const pathname = new URL(request.url).pathname;
  const name = '';
  const userInfo = {
    user: null,
    roles: [],
    permissions: [],
    resources: []
  };
  let authToken = '';
  const headers = {};
  const myCompanyInfo = {
    isError: false,
    type: 'success',
    message: '',
    data: {}
  };
  const eioInfo = {
    isError: false,
    type: 'success',
    message: '',
    data: {}
  };
  if (isAuthenticated) {
    const cookieHeader = await refreshSessionExpiration(request);
    if (cookieHeader) {
      headers['Set-Cookie'] = cookieHeader;
    }
    authToken = await getAuthToken(request);
    const isNeedToResetPassword = await checkIsNeedToResetPassword(request);
    if (isNeedToResetPassword && pathname !== '/reset-password') {
      return redirect('/reset-password?signed=' + authToken + '&action=change');
    }
    try {
      const apiService = new APIService(process.env.BASE_API_URL, authToken);
      try {
        const {
          permissions
        } = await apiService.GetRolePermissions();
        const userData = await apiService.GetUserInfo();
        const resources = await apiService.fetchResources();
        userInfo.user = userData || null;
        userInfo.roles = userData?.roles || [];
        userInfo.permissions = permissions || [];
        userInfo.resources = resources || [];
        logger.setUserIdentity(userData?.email || '');
      } catch (error) {
        console.error('[loader]', error);
        if (!error) {
          // AuthToken is not valid
          const session = await getSession(request.headers.get('Cookie'));
          return redirect(`/auth/login`, {
            headers: {
              'Set-Cookie': await destroySession(session)
            }
          });
        }
      }
    } catch (error) {
      console.error('[loader]', error);
    }
    try {
      const apiCompanyService = new CompanyServices(baseApiUrl, authToken);
      const result = await apiCompanyService.getMyCompany();
      myCompanyInfo.data = result.data;
    } catch (error) {
      console.error('[loader]', error);
    }
    if (userInfo.roles.map(role => role.name).includes(ROLE_NAME.EDUCATION_INSTITUTION_OFFICER)) {
      try {
        const institutionService = new InstitutionService(baseApiUrl, authToken);
        const result = await institutionService.getInstitutionList();
        eioInfo.data = {
          institutions: result || []
        };
      } catch (error) {
        console.error('[loader] cant load institutions', error);
      }
    } else {
      eioInfo.data = null;
    }
  }
  return json({
    host,
    isAuthenticated,
    name,
    baseApiUrl,
    authToken,
    userInfo,
    myCompanyInfo: myCompanyInfo?.data || null,
    eioInfo: eioInfo?.data || null
  }, {
    headers
  });
};
export const Head = createHead(_c = () => <>
    <Meta />
    <Links />
  </>);
_c2 = Head;
export default function App() {
  _s();
  const data = useLoaderData() || {};
  const {
    userInfo,
    isAuthenticated
  } = data;
  const location = useLocation();
  const navigate = useNavigate();
  const publicPath = ['tracking', 'validate', 'login'];
  const isPublic = publicPath.some(x => location.pathname.split('/').includes(x));
  useEffect(() => {
    if (!isPublic && (userInfo == null || isAuthenticated === false)) {
      navigate('/auth/login');
    }
  }, [userInfo, isAuthenticated]);
  return <>
      <MaterialThemeProvider>
        <GeneralContext.Provider value={{
        generalContextValue: data
      }}>
          <ConvertCurrencyProvider>
            <Outlet />
          </ConvertCurrencyProvider>
        </GeneralContext.Provider>
      </MaterialThemeProvider>
      <ScrollRestoration />
      <Scripts />
      {data.host === 'localhost' && <LiveReload />}
      <Head />
    </>;
}
_s(App, "u6O5TqoULjBGCgdBstBcxSv6Hnk=", false, function () {
  return [useLoaderData, useLocation, useNavigate];
});
_c3 = App;
export function ErrorBoundary() {
  _s2();
  const error = useRouteError();
  const location = useLocation();
  const navigate = useNavigate();
  const pushLog = () => {
    const baseUrl = typeof window !== "undefined" ? window.BASE_API_URL : "";
    const {
      message = 'Unknown error'
    } = error;
    const logger = new LoggerService();
    logger.setBaseUrl(baseUrl);
    logger.setUserIdentity('system');
    logger.error({
      message: message,
      details: {
        config: {
          url: location?.pathname
        }
      }
    });
  };
  useEffect(() => {
    pushLog();
  }, [error]);
  const backToHome = () => {
    navigate('/home');
  };
  return <>
      <MaterialThemeProvider>
        <Box className="bg-white w-full h-screen">
          <Column alignVertical='center' alignHorizontal='center'>
            <Typography variant="h2" className="text-3xl mb-3 text-umbra">Ooops - it seems like this system just met an unexpected error.</Typography>
            <Typography className="text-lg mb-6 text-umbra">Our software engineers should be automatically informed about this.</Typography>
            <Button onClick={backToHome}>
              <KeyboardReturn className="!text-white text-2xl" />
              <Typography className="!text-white">Take me back to Home Page</Typography>
            </Button>
          </Column>
        </Box>
      </MaterialThemeProvider>
      <Scripts />
      <ScrollRestoration />
      <Head />
    </>;
}
_s2(ErrorBoundary, "J729yK/OikaA7yfQAj8QOkbzO5Y=", false, function () {
  return [useRouteError, useLocation, useNavigate];
});
_c4 = ErrorBoundary;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "Head$createHead");
$RefreshReg$(_c2, "Head");
$RefreshReg$(_c3, "App");
$RefreshReg$(_c4, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;