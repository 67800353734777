// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Column/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Column/index.tsx");
  import.meta.hot.lastModified = "1740561133000";
}
// REMIX HMR END

import React from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
const Column = ({
  children,
  alignVertical = 'top',
  alignHorizontal = 'left'
}) => {
  const classes = clsx('w-full', 'h-full', 'flex', 'flex-col', {
    'items-start': alignHorizontal === 'left',
    'items-center': alignHorizontal === 'center',
    'items-end': alignHorizontal === 'right',
    'justify-start': alignVertical === 'top',
    'justify-center': alignVertical === 'center',
    'justify-end': alignVertical === 'bottom'
  });
  return <Box className={classes}>
      {children}
    </Box>;
};
_c = Column;
export default Column;
var _c;
$RefreshReg$(_c, "Column");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;